import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components';
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components';
import ContactBanner from '../components/Banner/ContactBanner';
import Hero from '../components/Hero'
import SEO from '../components/SEO'
import { BreadcrumbWrapper, H3Box } from '../styles/common/Element';
import { Col, Container, Row } from '../styles/common/Layout';
import mq from '../styles/media-queries';


const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`

const Box = styled.div`
  display: flex;
  flex: 0 0 calc(100% - 0rem);
  margin-bottom: 2rem;
  min-height: 150px;

  ${mq.md} {
    flex: 0 0 calc(33.33% - 2rem);
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
`

const BoxRed = styled.div`
  display: flex;
  flex: 0 0 calc(100% - 0rem);

  p {
    margin: 0;
    text-align: center;
  }
`

const BoxRedInner = styled.div`
  box-shadow: rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px;
  transition: all 0.2s ease-in 0s;
  width: 100%;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
  background-color: rgb(255, 56, 96) !important;
  color: rgb(255, 255, 255) !important;

`


const BoxInner = styled.div`
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px;
  color: rgb(74, 74, 74);
  transition: all 0.2s ease-in 0s;
  padding: 1.25rem;
  border-radius: 6px;
  width: 100%;
`


export default function CoronaPage({ pageContext, data }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <>
      <SEO
        title={data.page.pageName}
      />
      <Hero slug={data.page.slug.current} small>
        <h1 className="headline">{data.page.pageName}</h1>
      </Hero>
      <Container as="section">
        <Row>
          <Col>
            <BreadcrumbWrapper>
              <Breadcrumb crumbs={crumbs} crumbSeparator=" / " crumbLabel={'Corona Informationen'} />
            </BreadcrumbWrapper>
          </Col>
        </Row>
        <Row className="f-direction-col">
          <Col>
            <H3Box>AKTUELLE CORONAVIRUS INFORMATIONEN</H3Box>
            <p>Auch wir reagieren auf das Coronavirus und wollen Ihnen unser Konzept für die Krisenzeit vorstellen:</p>
            <BoxWrapper>
              <Box>
                <BoxInner>
                  <p>Wir waren gut informiert und haben uns rechtzeitig mit Desinfektionsmittel und persönlicher Schutzkleidung bevorratet.</p>
                </BoxInner>
              </Box>
              <Box>
                <BoxInner>
                  <p>Wir haben unsere Tagesabläufe umstrukturiert, so können wir Wartezeiten für Sie vermeiden und es ist genug Zeit für unsere verstärkten Hygienemaßnahmen.</p>
                </BoxInner>
              </Box>
              <Box>
                <BoxInner>
                  <p>In der Praxis tragen wir auch im Rezeptionsbereich immer Mundschutz, im Behandlungszimmer begrüßen wir Sie mit einem Mundschutz. Höchster Schutz für unsere Patienten und Mitarbeiter!</p>
                </BoxInner>
              </Box>
              <Box>
                <BoxInner>
                  <p>Alle kritischen Oberflächen, auch Türklinken, Wartezimmerstühle, Kunden-WC etc. werden regelmäßig desinfiziert.</p>
                </BoxInner>
              </Box>
              <Box>
                <BoxInner>
                  <p>Händedesinfektionsmittel steht für Sie bereit.</p>
                </BoxInner>
              </Box>
              <Box>
                <BoxInner>
                  <p>Es ist nicht sinnvoll notwendige Behandlungen lange aufzuschieben und auf früherkennende Diagnostik zu verzichten.</p>
                </BoxInner>
              </Box>
            </BoxWrapper>
            <BoxWrapper>
              <BoxRed>
                <BoxRedInner>
                  <p>Patienten mit Erkältungssymptomen bitten wir die Termine um mindestens zwei Wochen zu verschieben.</p>
                </BoxRedInner>
              </BoxRed>
            </BoxWrapper>
          </Col>
        </Row>
      </Container>
      <ContactBanner />
    </>
  )
}

export const query = graphql`
  query CoronaPageQuery {
    page: sanityPageContent(slug: {current: {eq: "corona"}}) {
      id
      pageName
      slug {
        current
      }
      subHeadline
      _rawText(resolveReferences: {maxDepth: 10})
      contentImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }

    }
  }
`;
